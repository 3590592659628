@import '../../style/themes/index';
@import '../../style/mixins/index';

@pageheader-prefix-cls: ~'@{ant-prefix}-page-header';

.@{pageheader-prefix-cls} {
  .reset-component;

  position: relative;
  padding: @page-header-padding-vertical @page-header-padding-horizontal;
  background: @component-background;

  &.@{pageheader-prefix-cls}-has-footer {
    padding-bottom: 0;
  }

  &-back {
    display: inline-block;
    padding: 4px 0;
    font-size: 16px;
    line-height: 100%;
    cursor: pointer;
    &-button {
      .operation-unit();

      color: @text-color;
    }
  }

  .@{ant-prefix}-divider-vertical {
    height: 14px;
    margin: 0 12px;
  }

  .@{ant-prefix}-breadcrumb {
    margin-bottom: 12px;
  }

  &-title-view {
    display: inline-block;
    &-title {
      display: inline-block;
      padding-right: 12px;
      color: @heading-color;
      font-weight: bold;
      font-size: 16px;
      line-height: 1.4;
    }

    &-sub-title {
      display: inline-block;
      padding-right: 12px;
      color: @text-color-secondary;
      font-size: 14px;
      line-height: 1.8;
    }

    &-tags {
      display: inline-block;
      vertical-align: top;
    }

    &-extra {
      position: absolute;
      top: 16px;
      right: @page-header-padding-horizontal;
      > * {
        margin-right: 8px;
      }
      > *:last-child {
        margin-right: 0;
      }
    }
  }

  &-content-view {
    padding-top: 12px;
  }

  &-footer {
    margin: 0 -8px;
    padding-top: 24px;
    .@{ant-prefix}-tabs-bar {
      margin-bottom: 1px;
      border-bottom: 0;
      .@{ant-prefix}-tabs-nav .@{ant-prefix}-tabs-tab {
        padding: 12px 8px;
        padding-top: 0;
      }
    }
  }
}
